const BACKEND_URL = "science-news-2023.";

function isBackendUrl(): boolean {
  return location.host.startsWith(BACKEND_URL);
}

function processBackendUrls(): void {
  function replaceWwwByBackedUrl(anchor: Element): void {
    const href = anchor.getAttribute("href") + "";
    anchor.setAttribute("href", href.replace("www.", BACKEND_URL));
  }

  const selectors = [`a[href*='www.']:not(footer.Footer *, nav.Ibar *)`];
  selectors.forEach((s) =>
    document.querySelectorAll(s).forEach(replaceWwwByBackedUrl)
  );
}

export default defineNuxtPlugin(async () => {
  setTimeout(() => {
    if (isBackendUrl()) {
      processBackendUrls();
    }
  }, 1000);
});
